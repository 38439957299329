@import './base.css';
@import './emojiMart.css';

/** purgecss start ignore */
:root {
  --color-amie-pink: #f6a6a6;
  --shadow-wysiwyg-inner: rgba(0, 0, 0, 0.04);
  --shadow-wysiwyg-outer: rgba(0, 0, 0, 0.12);
}

.dark {
  --shadow-wysiwyg-inner: rgba(134, 141, 150, 0.12);
  --shadow-wysiwyg-outer: rgba(134, 141, 150, 0.12);
}
/** purgecss end ignore */

html {
  width: 100%;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

/* For Chrome's blue outline. Style focused buttons with a border instead. */
button:focus,
div:focus {
  outline: 0 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* https://css-tricks.com/almanac/selectors/p/placeholder/#firefox-placeholder-color */
::-moz-placeholder {
  opacity: 1;
}

#__next {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* removed after page load */
.preload * {
  transition: none !important;
}

[data-reach-popover] {
  z-index: 50;
}

/* Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ss01 {
  font-feature-settings: 'ss01' on;
}

.ss02 {
  font-feature-settings: 'ss02' on;
}

.blur-bg {
  backdrop-filter: saturate(180%) blur(5px);
}

.fade-bg {
  -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.fade-bg-top {
  -webkit-mask-image: linear-gradient(to top, black 5%, transparent 100%);
  mask-image: linear-gradient(to top, black 5%, transparent 100%);
}

.fade-bg-right {
  -webkit-mask-image: linear-gradient(to right, black 5%, transparent 100%);
  mask-image: linear-gradient(to right, black 5%, transparent 100%);
}

.fade-bg-right-timezone {
  -webkit-mask-image: linear-gradient(
    to right,
    black 5%,
    black 70%,
    transparent 100%
  );
  mask-image: linear-gradient(to right, black 5%, black 70%, transparent 100%);
}

.half-fade-bg-right {
  -webkit-mask-image: linear-gradient(to right, black 50%, transparent 100%);
  mask-image: linear-gradient(to right, black 50%, transparent 100%);
}

/*
  This forces react-laag layers to always appear on top.
  As recommended by their documentation:
  https://github.com/everweij/react-laag#z-index-/-container
*/
#layers {
  z-index: 999999;
}
